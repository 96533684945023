
export default {
  name: "SwiperBrandes",

  data() {
    return {
      swiperOption: {
        loop: true,
        freeMode: true,
        spaceBetween: 30,
        grabCursor: true,
        slidesPerView: "auto",
        loopedSlides: 34,
        autoplay: {
          delay: 0,
          disableOnInteraction: true,
        },
        speed: 1000,
        freeModeMomentum: false,
        autoplayDisableOnInteraction: false,
        coverflowEffect: {
          depth: 100,
          modifier: 1,
          rotate: 5,
          stretch: 0,
        },
        freeModeMomentumBounce: false,
        freeModeMomentumRatio: 0.1,
        freeModeMomentumVelocityRatio: 0.8,
        freeModeSticky: true,
      },

      slides: [
        {
          image: "/assets/images/home/brandes/01.png",
        },
        {
          image: "/assets/images/home/brandes/02.png",
        },
        {
          image: "/assets/images/home/brandes/03.png",
        },
        {
          image: "/assets/images/home/brandes/04.png",
        },
        {
          image: "/assets/images/home/brandes/05.png",
        },
        {
          image: "/assets/images/home/brandes/06.png",
        },
        {
          image: "/assets/images/home/brandes/07.png",
        },
        {
          image: "/assets/images/home/brandes/08.png",
        },
        {
          image: "/assets/images/home/brandes/09.png",
        },
        {
          image: "/assets/images/home/brandes/10.png",
        },
        {
          image: "/assets/images/home/brandes/11.png",
        },
        {
          image: "/assets/images/home/brandes/12.png",
        },
        {
          image: "/assets/images/home/brandes/13.png",
        },
        {
          image: "/assets/images/home/brandes/14.png",
        },
        {
          image: "/assets/images/home/brandes/15.png",
        },
        {
          image: "/assets/images/home/brandes/16.png",
        },
        {
          image: "/assets/images/home/brandes/17.png",
        },
        {
          image: "/assets/images/home/brandes/18.png",
        },
        {
          image: "/assets/images/home/brandes/19.png",
        },
        {
          image: "/assets/images/home/brandes/20.png",
        },
        {
          image: "/assets/images/home/brandes/21.png",
        },
        {
          image: "/assets/images/home/brandes/22.png",
        },
        {
          image: "/assets/images/home/brandes/23.png",
        },
        {
          image: "/assets/images/home/brandes/24.png",
        },
        {
          image: "/assets/images/home/brandes/25.png",
        },
        {
          image: "/assets/images/home/brandes/26.png",
        },
        {
          image: "/assets/images/home/brandes/27.png",
        },
        {
          image: "/assets/images/home/brandes/28.png",
        },
        {
          image: "/assets/images/home/brandes/29.png",
        },
        {
          image: "/assets/images/home/brandes/30.png",
        },
        {
          image: "/assets/images/home/brandes/31.png",
        },
        {
          image: "/assets/images/home/brandes/32.png",
        },
        {
          image: "/assets/images/home/brandes/33.png",
        },
        {
          image: "/assets/images/home/brandes/34.png",
        },
      ],
    };
  },
};
